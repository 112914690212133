.tag-popover {
 max-width: 300px;
}

.oneline {
 overflow: hidden;
 white-space: nowrap;
 text-overflow: ellipsis;
}

.full-loader {
 width: 100%;
 height: 100vh;
 position: fixed;
 top: 0;
 right: 0;
 bottom: 0;
 left: 0;
 display: flex;
 align-items: center;
 justify-content: center;
}

.mail-preview-body {
 img {
  max-width: 100%;
  height: auto;
 }
//  a{
//     @extend .colored-link-1
//  }
}

.shedl-email {
 .card {
  border: 1px solid #ccc;
  padding: 10px 15px;
  cursor: pointer;
  text-align: center;
  display: block;
  &.active {
   background-color: $primary;
   i,
   h5,
   p {
    color: #fff;
   }
  }
  i {
   font-size: 20px;
   margin-bottom: 7px;
  }
  h5 {
   margin-bottom: 3px;
   font-size: 16px;
  }
  p {
   color: #aaa;
   margin: 0;
   font-size: 14px;
  }
 }
}

.sms-text-area {
 position: relative;
 overflow: hidden;
 border: 1px solid #ced4da;
 .ant-input {
  padding-top: 46px;
  border: none;
 }
 .sm-menubar {
  background-color: rgb(248, 248, 248);
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  position: absolute;
  margin: 0;
  padding: 6px;
  list-style: none;
  z-index: 2;
  display: flex;
  li {
   width: 260px;
   &:not(:last-of-type) {
    margin-right: 10px;
   }
  }
 }
}

.ant-table-filter-dropdown {
 .ant-dropdown-menu {
  li {
   padding: 7px 9px;
   .ant-dropdown-menu-title-content {
    .ant-checkbox-wrapper {
     margin-right: 0;
    }
    display: flex;
    align-items: center;
   }
  }
 }
 .ant-table-filter-dropdown-btns {
  padding: 4px 9px;
 }
}

.filter-table-custom {
 .ant-checkbox-group {
  display: flex;
  flex-direction: column;
  .filter-menu {
   padding: 7px 10px;
   display: flex;
   align-items: center;
  }
 }
 .footer-button {
  padding: 7px 10px;
  display: flex;
  justify-content: space-between;
  .ant-btn:first-of-type {
   margin-right: 4px;
  }
 }
}

.bad-label {
 color: $danger;
 text-decoration: line-through;
}

.search-card {
 .ant-input-search {
  border-radius: 30px;
  .ant-input {
   border-top-left-radius: 30px;
   border-bottom-left-radius: 30px;
  }
  .ant-input-search-button {
   padding-left: 30px;
   padding-right: 30px;
   border-top-right-radius: 30px !important;
   border-bottom-right-radius: 30px !important;
  }
 }
}

.hio-tag {
 max-width: 100%;
 span {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
 }
}

.pro--card {
 border-radius: 10px;
 // border: 1px solid rgba($primary, .5);
 margin: 0;
 cursor: pointer;
 box-shadow: 1px 1px 10px -3px #0004;
 position: relative;

 height: 100%;
 .ant-card-body {
  padding-top: 30px;
  height: 100%;
  //   display: flex;
  //   align-items: center;
 }
 //  .ant-col {
 //   position: relative;
 //   height: 100%;
 .pro--card-ul {
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  height: 100%;
  .pro-crd-label {
   font-weight: 500;
   color: black;
   padding-left: 8px;
  }
  .pro-card-brokerage {
   // width: fit-content;
   // max-width: 500px;
   white-space: nowrap;
   overflow: hidden;
   display: inline-block;
   text-overflow: ellipsis;
   // span{
   //   width: 100%;
   // }
   // border: 1px solid #ddd;
   // margin: 0;
   // span{
   //   width: 10%;
   // }
  }
 }
 //  }
 &.pro--card-checked {
  background-color: lighten($primary, 50);
 }
 .open-house-tag {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.2rem 0.5rem;
  color: white;
  background-color: #ff6600;
  // z-index: 100;
  font-size: 12px;
  border-radius: 10px 0px 10px 0;
 }
 .pro-selction {
  position: absolute;
  right: 10px;
  top: 10px;
 }
 .pro--card-ul {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  // row-gap: 5px;
  li {
   display: flex;
   align-items: center;
   font-size: 14px;
   color: #444;
   line-height: 1.6;

   p {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    margin: 0;
   }
   // overflow: hidden;
  }
//   .pro-crd-bbctt-wrap {
//    .wrap {
//     width: 100%;
//     // overflow: hidden;
//     margin: 0;
//    }
//    .building-type {
//     overflow: hidden;
//     display: -webkit-box;
//     -webkit-line-clamp: 1;
//     -webkit-box-orient: vertical;
//     white-space: initial;
//    }
//   }
 }
}

.notfn-description {
 font-size: 14px;
 color: black;
}

.notification-list {
 .ant-list-item {
  align-items: flex-start;
 }
 .title {
  margin-bottom: 8px;
  font-size: 17px;
  font-weight: 500;
 }
 .primary-color {
  color: $primary;
 }
 .greenColor {
  background-color: #3f6600;
 }
 .reColor {
  background-color: #d4380d;
 }
 .orangeColor {
  background-color: #fa8c16;
 }
 .pinkColor {
  background-color: #9e1068;
 }
 .violotColor {
  background-color: #391085;
 }
}

.svg-actionblock {
 svg {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
 }
 button {
  padding: 0;
  position: relative;
 }
}

.listing-blocks {
 .listing-block {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-start;
  &:not(:last-child) {
   border-bottom: 1px solid #e1e1e1;
   padding-bottom: 10px;
   margin-bottom: 10px;
  }
  .listing-lft-cntnt {
   display: inline-block;
   width: calc(100% - 115px);
   .price {
    font-weight: 600;
    font-size: 18px;
    .prop-tag {
     vertical-align: middle;
    }
   }
   ul.date {
    padding-left: 0;
    list-style: none;
    margin: 0;
    li {
     display: inline-block;
     line-height: 15px;
     font-size: 15px;
     &:not(:last-child) {
      margin-right: 10px;
      padding-right: 10px;
      border-right: 2px solid #8c8c8c;
     }
    }
   }
  }
  .listing-rgt-cntnt {
   display: inline-block;
   width: 115px;
   text-align: right;
   a.view {
    display: block;
    width: max-content;
    max-width: 100%;
    background-color: $primary;
    color: #ffffff;
    font-weight: 600;
    text-align: center;
    border-radius: 4px;
    padding: 1px 10px 2px 10px;
    margin: 0 0 0 auto;
   }
   .updated-day {
    font-size: 14px;
   }
  }
 }
}

.history-blocks {
 .history-single-blocks {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  .label-values {
   color: black;
   font-weight: 500;
   margin-left: 8px;
  }
  &:not(:last-child) {
   border-bottom: 1px solid #8c8c8c;
   margin-bottom: 10px;
   padding-bottom: 10px;
  }
  .price-and-status {
   display: flex;
   align-items: center;
   .price {
    font-size: 18px;
    font-weight: 500;
    color: $danger;
   }
   .status {
    margin-left: 1rem;
    border: 2px solid #a8a7a7;
    border-radius: 8px;
    padding: 0 10px;
    font-weight: 600;
    font-size: 12px;
   }
  }
 }
}
.search-sugg-block {
 position: relative;
 .suggestions {
  position: absolute;
  background-color: white;
  top: 50px;
  left: 0;
  right: 0;
  width: 100%;
  max-height: 500px;
  overflow: auto;
  z-index: 1;

  box-shadow: 1px 1px 10px -3px rgba(0, 0, 0, 0.2666666667);
  ul {
   li {
    padding: 8px 10px;
    &:nth-child(odd) {
     background-color: #f2f2f2;
    }
   }
  }
 }
}
.listing-modal-rightside-email {
 max-height: 450px;
 overflow: auto;
 overflow-x: hidden;
}
.listing-modal-rightside-sms {
 max-height: 284px;
 overflow: auto;
 overflow-x: hidden;
}

@media (max-width: 767px) {
 .listing-blocks {
  .listing-block {
   .listing-lft-cntnt {
    ul.date {
     li {
      font-size: 13px;
      &:not(:last-child) {
       margin-right: 8px;
       padding-right: 8px;
      }
     }
    }
   }
  }
 }
}

.unread-chat {
 height: 23px;
 width: 23px;
 background-color: $primary;
 border-radius: 50%;
 display: flex;
 justify-content: center;
 align-items: center;
 font-size: 10px;
 line-height: 10px;
 position: absolute;
 bottom: 0;
 left: 0;
}

.similar-prop-section {
 .similar-prop-btn {
  width: 100%;
  background-color: $primary;
  color: white;
  height: auto;
  padding: 1px 5px;
  font-weight: 500;
 }
}

.action-completed-leads {
 margin-bottom: 10px;
}

.action-active-leads {
 margin-bottom: 10px;
 cursor: pointer;
}

.forgot-pw-text {
 display: flex;
 align-items: center;
 justify-content: center;
 margin-top: 8px;
}

.price-ranger-filter {
 .ant-input-number-input {
  cursor: auto !important;
  background-color: white;
  color: black;
 }
}

.show-more {
 color: $danger;
 cursor: pointer;
}

.share-block-modal {
 .modal-body {
  padding: 50px;
 }
 .modal-header {
  padding: 16px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  h3 {
   margin-bottom: 4px;
  }
  p {
   color: #000000;
  }
 }
 .share-ui-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  align-items: flex-start;
  // row-gap: 10px;
  li {
   width: 100%;
   button {
    text-align: left;
    background-color: transparent;
    border: none;
    font: inherit;
    color: inherit;
    cursor: pointer;
    transition: all 0.5s ease;
    width: 100%;
    padding: 15px 15px !important;
    border-radius: 4px;
    &:hover {
     background-color: rgb(228, 228, 228) !important;
    }
    i {
     margin-right: 6px;
    }
   }
  }
 }
}

.a-tag-style {
 color: #007bff;
 &:hover,
 &:active,
 &:focus {
  color: #0056b3;
 }
}

.pro-card1 {
 display: flex;
 flex-direction: row;
 align-items: center;
 justify-content: center;
 .img-parent {
  display: inline-block;
  width: 95px;
 }
 .ul-parent {
  display: inline-block;
  width: calc(100% - 95px);
 }
}

.secondary-btn {
 background-color: #3f3f3f !important;
 border-color: #3f3f3f !important;
}

.filter-dot-icon {
 .ant-badge-dot {
  top: 6px !important;
  right: 28px !important;
  width: 12px !important;
  height: 12px !important;
  background-color: rgb(5, 172, 5);
 }
}

.no-data-empty-compo {
 .no-data-image {
  font-size: 90px;
  color: $primary;
  font-weight: 300;
 }
 .no-data-heading {
  color: $primary;
  font-weight: 500;
  font-size: 17px;
 }
 .no-data-description {
  color: rgb(117, 117, 117);
 }
}

.assign-tag-btn {
}

.bell-icon-btn {
 // button{
 i {
  font-size: 19px;
  // color: #f7f7f7;
 }
 // }
}

.overflow-ellips-text {
 white-space: nowrap;
 overflow: hidden;
 text-overflow: ellipsis;
}

.auto-size-text-area {
 outline: none;
 box-shadow: none;
 padding: 12px;
 border-color: #d9d9d9;
 transition: all 0.3s;
 &:focus {
  border-color: $primary;
  box-shadow: 0 0 0 2px rgba(237, 28, 36, 0.2);
 }
 &:hover {
  border-color: $primary;
 }
}

.my-profile{
    .my-profile-left{
        margin-top: 20px;
        font-weight: 500;

        div{
            padding:10px 0px;
            display: flex;
            word-break:break-all;
            align-items: center;
            i{
                font-size: 18px;
                padding-right: 10px;
                
            }
             svg{
                margin-right: 10px;
            }
        }
    }
}

.colored-link{
    color: #ED1C24 !important;
    &:hover{
        color: #d41118 !important;
    }
}

.colored-link-1{
    color: $primary !important;
    &:hover{
        // color: #d41118 !important;
        opacity: 0.8 !important;
    }
}


.pro-crd-bbctt-wrap{
    display: inline-block !important;
}


.pro-bbc{
    // display: flex;
    // display: inline-flex;
    // gap: 8px;
    display: inline-block;
    .bbc{
        display: inline-block;
        margin-right: 8px;
        // &:last-child {
        //     margin-right: 8px;
        // }
        // display: flex;
        .span{
            margin-left: 4px;
        }
    }
    .sqft{
        svg{
            width: 15px;
            height: 15px;
            object-fit: contain;
            *{
                fill: #555;
            }
        }
    }
    
}

.ant-tooltip {
    .ant-tooltip-inner {
     .tssblock-span {
      white-space: break-spaces !important;
     }
    }
}

.tox-toolbar-overlord,
.tox-tinymce-aux{
    button[aria-label="Metadata and document properties"]{
        display: none;
    }
}
.template-view-modal{
    .email-template-body{
        img{width: 100%;}
        a{
          @extend .colored-link-1  
        }
    }
}

.btn-secondary-custom{
    background-color: #606060 !important;
    border-color: #606060 !important;
    color: white !important;
    &:hover{
        opacity: 0.9 !important;
    }
}
