.base-selecter-blk {
 .selector-search {
  margin-bottom: 10px;
 }
 ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-y: auto;
  max-height: 400px;
  li {
   a {
    display: block;
    transition: all 0.5s ease;
    padding: 7px 15px;
    color: #222;
    &.active {
     background-color: #eee;
    }
    &:hover {
     background-color: #ddd;
    }
   }
  }
 }
}
.base-selector-sp-blk {
 .selector-search {
  margin-bottom: 10px;
 }
 ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-y: auto;
  max-height: 400px;
  li {
   a {
    transition: all 0.5s ease;
    padding: 7px 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #555 !important;
    &.active {
     background-color: #eee;
    }
    &:hover {
     background-color: #eee;
    }
    .base-selector-sp-title {
     font-weight: 500;
     font-size: 16px;
     margin: 0;
    }
   }
  }
 }
}

.modal-has-base-selector {
 .ant-modal-content {
  overflow: hidden;
  padding: 0;
  .ant-modal-header {
   padding: 15px;
   margin: 0;
   background-color: #eee;
  }
  .ant-modal-footer {
   padding: 15px;
   margin: 0;
   background-color: #eee;
  }
 }
 .selector-search {
  margin: 0 !important;
  padding: 10px 15px !important;
  border: 1px solid #eee;
  // background-color: #f7f7f7                           ;
 }
}

.action-blk-btn {
 background-color: #e9e9e9 !important;
 svg{
  width: unset !important;
  height: unset !important;
 }
 @mixin btnStyles {
  padding: 0;
  height: unset;
  width: 170px;
  display: flex;
  align-items: center;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  z-index: 0;
 }
 @include btnStyles;
 .icon {
  color: white;
  padding: 4px 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 30px;
 }

 .btn-text {
  padding-left: 10px;
  padding-right: 10px;
  max-width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
 }
 @mixin actionBlkIcon($name, $color) {
  .icon-#{$name} {
   background-color: $color;
  }
 }

 @include actionBlkIcon('primary', $primary);
 @include actionBlkIcon('success', $success);
 @include actionBlkIcon('secondary', $secondary);
 @include actionBlkIcon('info', $info);
 @include actionBlkIcon('warning', $warning);
 @include actionBlkIcon('danger2', $danger);
 @include actionBlkIcon('alpha', $alpha);
 @include actionBlkIcon('beta', $beta);
 @include actionBlkIcon('gamma', $gamma);
 @include actionBlkIcon('gray', $gray);

 &.blk-btn-disabled {
  button {
   @include btnStyles;
  }

  .icon {
   background-color: unset !important;
   color: unset !important;
  }
 }
}
