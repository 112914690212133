a {
 // &:not(.mail-preview-body a) {
 color: #555;
 // }
}
body {
 font-family: 'Poppins', 'sans-serif';
}

.upload-list-inline .ant-upload-list {
 display: flex;
 overflow: auto;
 width: 100%;
 padding-bottom: 10px;
 gap: 8px;
 &::-webkit-scrollbar {
  // width: 0;
  height: 8px;
  // background: transparent;
 }
 .ant-upload-list-picture-container {
  &:not(:last-child) {
   margin-right: 6px;
  }
 }
}

.upload-list-inline {
 .ant-upload-list-item {
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding: 2rem 1rem;
  border: 1px solid #ccc;
  border-radius: 3px;
  .file-name {
   max-width: 200px;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   flex-grow: 1;
  }

  .file-size {
   margin: 0 8px;
   color: rgba(0, 0, 0, 0.45);
   white-space: nowrap;
  }
  .ant-upload-list-item-thumbnail {
   margin-right: 8px;
  }

  .ant-upload-list-item-card-actions {
   display: flex;
   gap: 6px;
   position: relative;
   button {
    width: 28px;
    height: 30px;
   }
   .fa-eye,
   .fa-trash-alt {
    font-size: 16px;
   }
  }
 }
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
 border: none !important;
}
.admin-layout .ant-layout-sider {
 border-right: 1px solid rgba(0, 0, 0, 0.06);
}
