.sw-to-bar {
 display: flex;
 flex-wrap: wrap;
 position: relative;
 padding-right: 35px;

 .sw-to-bar-tag {
  border: 1px solid #ccc;
  display: flex;
  line-height: 1;
  padding: 3px 7px;
  border-radius: 4px;

  :not(.read-only){
    span {
    width: calc(100% - 18px);
    padding-right: 5px;
    }
  }
  a {
   display: block;
   width: 18px;
   display: flex;
   align-items: center;
   justify-content: center;
  }
 }

 .sw-to-bar-label {
  color: #777;
 }
 .input-to-area {
  padding-left: 6px;
  flex: 1;
  flex-wrap: wrap;
  padding-top: 6px;
  padding-bottom: 6px;

  .sw-bar-container {
   overflow: hidden auto;
   max-height: 78px;
   .sw-cc-container{
    display: flex;
    flex-direction: column;
    .sw-cc-input{
      min-width: 200px;
      max-width: 100%;
      margin-bottom: 4px;
      // &.border-input{
      //   input{
      //     // border-bottom: 1px solid #ccc;
      //   }
      // }
    }
    .selected-item{
      margin-bottom: 4px;
      min-height: 26px;
      display: flex;
      align-items: center;
      font-size: 14px;
      cursor: pointer;
      &.active-item{
        border-color: $primary;
      }
    }
    .sw-cc-suggestion{
      position: absolute;
      opacity: 1;
      transform: none;
      background-color: white;
      width: calc(100% - 6px);
      z-index: 10;
      padding: 10px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      margin-top: 1px;
      transition: background-color 250ms linear;
      cursor: pointer;
      &:hover{
        background-color: #f1f1f1;
      }
    }
   }
  }
 }
 .sw-to-bar-action {
  position: absolute;
  right: 0;
  top: 0;
  width: 32.5px;
  height: 30px;
  padding: 0 11px;
  padding-top: 6px;
  padding-bottom: 6px;
 }
 .sw-bar-placeholder {
  flex: 1 1;
  overflow: hidden;
  color: #bfbfbf;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
 }
}

.sw-to-bar-custom-input {
 .ant-form-item-control-input {
  border: 1px solid #ccc;
 }
 .ant-form-item-has-error {
  .ant-form-item-control-input {
   border: 1px solid #fa4646;
   box-shadow: 0 0 0 2px rgba(237, 28, 36, 0.2);
  }
 }
}
