.templates-list-overlay {
 height: -webkit-fill-available;
 max-width: 100vw;

 .ant-modal-content {
  width: 100vw;
  height: 100vh;
  top: 0;
  overflow: auto;
 }
 .ant-modal-centered::before {
  content: unset;
 }
 .ant-modal-footer {
  display: none;
 }
}

.template-sugg-block {
 position: relative;
 .suggestions {
  position: absolute;
  background-color: white;
  top: 36px;
  left: 0;
  right: 0;
  width: 100%;
  max-height: 300px;
  overflow: auto;
  z-index: 20;
  box-shadow: 1px 1px 10px -3px rgba(0, 0, 0, 0.2666666667);

  ul {
   li {
    padding: 8px 10px;
    cursor: pointer;
    span{
        color: black;
    }
    &:hover{
        p,span{
        color: $primary;
    }
    }
    &:nth-child(odd) {
     background-color: #f2f2f2;
    }
   }
  }
 }
}
