.crmSidebar {
 .logo {
  width: 100%;
  height: 64px;
  padding: 10px;
  img {
   width: 100%;
   height: 100%;
   object-fit: contain;
  }
  svg {
   max-width: 100%;
   height: auto;
  }
 }

 .sidebar-menu {
  height: calc(100% - 65px);
  overflow-y: auto;
  svg {
   width: 18px;
   height: 18px;
   //    path {
   //     fill: #000;
   //    }
  }
  .ant-menu-item,
  .ant-menu-submenu {
   width: calc(100% - 1px) !important;
  }
  //   var(--ant-primary-1)
  .ant-menu-submenu-selected {
//    background-color: var(--ant-primary-1);
   .ant-menu-submenu-arrow {
    color: $primary;
   }
  }

  &.collapsed-sidebar{
    .ant-menu-submenu-selected {
        background-color: var(--ant-primary-1);
    }
  }
 }
}
