.action-timeline-view{
    margin-left: 20px;
    .tim-dot{
        text-align: center;
        h5{
            color: #666;
        }
        i{
            font-size: 19px;
        }
    }
    .ant-timeline-item-content{
        margin: 0 0 0 45px;
    }
    .ap-steps-email-body{
        img{
           max-width: 100%;
        }
    }
}



.page-size-modal{
    height: -webkit-fill-available;
    max-width: 100vw;

  .ant-modal-content {
    width: 100vw;
    height: 100vh;
    top: 0;
    overflow: auto;
  }
  .ant-modal-centered::before {
    content: unset;
  }
  .ant-modal-footer {
    display: none;
  }
}

.market-updates-modal{
  .__body{
    img{
      width: 100%;
    }
  }
}

.template-tree-select-popover {
  padding: 0;
  height: 200px;
  .ant-popover-inner-content {
    padding: 0;
    .content {
      padding: 8px;
      .parent-item {
        padding: 4px;
        cursor: pointer;
        &:hover {
          background-color: #f0efef;
        }
      }
      .child-item{
        padding: 8px;
        padding-left: 16px;
        cursor: pointer;
        &:hover {
          background-color: #f0efef;
        }
        &.active{
          background-color: #ffedeb;
          font-weight: 600;
        }
      }
    }
  }
}


.hierarchical-select {
  position: relative;
  // width: 300px;

  // &__input {
  //   width: 100%;
  //   padding: 8px 12px;
  //   border: 1px solid #d9d9d9;
  //   border-radius: 4px;
  //   font-size: 14px;
    
  //   &:focus {
  //     outline: none;
  //     border-color: #4096ff;
  //     box-shadow: 0 0 0 2px rgba(64, 150, 255, 0.2);
  //   }
  // }
  &__dropdown-wrapper{
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1;
  }
  &__input {
    &--has-edit {
      width: calc(100% - 70px);
    }
    &--has-button {
      width: calc(100% - 35px);
    }
    
  }

  &__dropdown {
    // position: absolute;
    // top: 100%;
    // left: 0;
    // right: 0;
    margin-top: 4px;
    max-height: 300px;
    overflow: auto;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1000;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $primary;
      border-radius: 3px;
    }
  }

  &__folder {
    &-header {
      padding: 8px 12px;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: background-color 0.3s;
      // border-bottom: 1px solid #ccc;
      &:hover {
        background-color: #f5f5f5;
      }

      .icon {
        margin-right: 8px;
        width: 16px;
        height: 16px;
      }
    }

    &-name {
      font-weight: 500;
    }
  }

  &__template {
    padding: 8px 12px 8px 36px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #e6f4ff;
    }

    &--selected {
      background-color: #e6f4ff;
    }
  }

  &__loading {
    padding: 8px 12px;
    text-align: center;
    color: #8c8c8c;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  &__empty {
    padding: 8px 12px;
    text-align: center;
    color: #8c8c8c;
  }
}