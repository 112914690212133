
.inbox-page{
    display: flex;
    flex-direction: column;
    .inbox-page-header{
        height: 50px;
        display: flex;
        align-items: center;
        padding: 3px 15px;
        ul.head-links{
            display: block;
            list-style: none;
            margin: 0;
            padding: 0;
            li{
                display: inline-block;
                &:not(:last-child){
                    margin-right: 10px;
                }
                a{
                    display: block;
                    border: 1px solid #555;
                    padding: 5px 10px;
                    border-radius: 4px;
                    font-weight: 400;
                    font-size: 14px;
                    &.active{
                        background-color: $primary;
                        color: #fff;
                        border-color: $primary;
                    }
                }
            }
            }
    }
    .inbox-page-body{
        
        flex: 1;
        display: flex;
        position: relative;
     
        .inbox-container{
            padding-left: 15px;
             padding-right: 15px;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            height: 100%;
            overflow: auto;
            display: flex;
            width: 100%;
            >.ant-row{
                height: 100%;
                width: 100%;
                >div{
                    height: 100%; 
                }
            }
        
            // >div{
            //     padding-left: 7px;
            //     padding-right: 7px;
            // }
            .inbox-sidenav{
                width: 425px;
                height: 100%;
                overflow: auto;
                .left-inbox-links{
                 height: 100%;
                    overflow-y: auto;
                    padding: 3px 5px 5px 5px;   
                    list-style: none;
                    margin: 0;
                    @include smoothScroll();
                    li{
                        margin-bottom: 15px;
                        .inbox-item-link{
                            -moz-box-shadow: 0 0 3px 1px #0d73c14f;
                            -webkit-box-shadow: 0 0 3px 1px #0d73c14f;
                            box-shadow: 0 0 3px 1px #0d73c14f;
                        }
                    }
                }
            }
            .inbox-content-area{
                flex: 1;
                // width: calc(100% - 400px);
                // padding: 0px 15px;
                width: calc(100% - 430px);
               
                .messge-list-inner{
                    height: 100%;
                    .ant-card-head{
                        line-height: 50px;
                        min-height: 50px;
                        padding: 0 15px;
                        text-transform: capitalize;
                        max-height: 50px;
                        .ant-card-head-title,
                        .ant-card-extra{
                            padding: 0;
                        }
                    }
                    .ant-card-body{
                        height: calc(100% - 50px);
                        overflow: auto;
                        padding: 15px;
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        .sms-msg-container{
                         
                            position: relative;
                            flex: 1;
                            // @include smoothScroll();
                            // overflow-y: auto;
                            // display: flex;
                        //    flex-direction: column-reverse;
                            .sms-msg-flex{
                                position: absolute;
                                top: 0;
                                height: 100%;
                                width: 100%;
                                // height: 100%;
                                @include smoothScroll();
                                overflow-y: auto;
                                display: flex;
                                flex-direction: column-reverse;
                                .chat-block{
                                    display: flex;
                                    flex-direction: column-reverse;
                                }

                            }
                        }
                        .sms-msg-input{
                            position: relative;
                            padding: 15px 0;
                            .ant-form-item{
                                margin: 0;
                            }
                            textarea{
                                padding-right: 45px;
                            }
                            .send-icon{
                                position: absolute;
                                top: 27px;
                                right: 10px;
                                cursor: pointer;
                            }
                        }
                      
                    }
                           
                }
                .schedule-inbox-body{
                    img{
                        max-width: 100%;
                    }
                }
            }
        }
        
    }
}







.middle-inbox-area{
    max-height: calc(100vh - 153px);
    overflow-y: auto;
    padding: 3px 5px 5px 5px;   
    list-style: none;
    margin: 0; 
    @include smoothScroll();
}
.active{
    >.inbox-item-link{
        background-color: rgb(226, 239, 245);

    }
}
.inbox-item-link{
    position: relative;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
    border-radius: 4px;
    &.inbox-item-link-active{
        background-color: rgb(226, 239, 245);
    }
    .inb-link{
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 1;
        // &.active{
        //     background-color: rgb(226, 239, 245);
        // }
    }
    .inbox-action-blk{
        display: inline-block;
        position: relative;
        z-index: 2;
    }
    // a.hover-link{
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     right: 0;
    //     bottom: 0;
    //     width: 100%;
    //     height: 100%;
    //     z-index: 2;
    // }
    .inbox-icon{
        width: 45px;
        height: 45px;
        display: inline-block;
        text-align: center;                
        span{
            display: flex;
            width: 100%;
            align-items: center;
            border-radius: 50%;
            justify-content: center;
            width: 45px;
            height: 45px;
            background-color: $primary;
            text-align: center;
            i{
                color: #fff;
                font-size: 20px;
            }
        }
    }
    .inbox-content{
        width: calc(100% - 45px);
        display: inline-block;
        padding-left: 15px;
        h4{
            font-size: 14px;
            font-weight: 500;
            color: #000;
            margin-bottom: 3px;
        }
        h5{
            font-size: 12px;
            color:#9a9a9a;
            font-weight: 400;
            margin-bottom: 5px;
            word-wrap: break-word;
        }
        .msg-status{
            font-size: 11px;
            .status{
                text-transform: capitalize;
                border:1px solid $primary;
                display: block;
                padding: 2px 5px;
                border-radius: 4px;                        
                width: max-content;
                max-width: 100%;
                color: $primary;
                font-weight: 500;
            }
            .date{
                // text-align: right;
                color: #9a9a9a;
            }
        }
    }
}
.ant-collapse{
    background-color: #fff;
    .ant-collapse-item{
        margin-bottom: 10px;
        -moz-box-shadow: 0 0 3px 1px #0d73c14f;
        -webkit-box-shadow: 0 0 3px 1px #0d73c14f;
        box-shadow: 0 0 3px 1px #0d73c14f;
        border-radius: 4px;
        overflow: hidden;
        .ant-collapse-header{
            padding: 10px 50px 10px 10px;
            border-bottom: 1px solid #e3e3e3;
            .inbox-item-link{
                padding: 0;
            }
        }

    }
}

.profile-block-item{
    max-height: calc(100vh - 153px);
    overflow-y: auto;
    padding: 0 5px;
    &::-webkit-scrollbar {
        background:lightgray;
        width: 8px;
    }
    
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgb(180, 180, 184); 
        border-radius: 15px;
    }
    
    &::-webkit-scrollbar-thumb {
        border-radius: 15px;
        -webkit-box-shadow: inset 0 0 6px rgba(77, 77, 77, 0); 
        background:$primary;
    } 
    .profile-head{
        // background-color: #fafafa;
        background-color: #fff;
        border: 1px solid #f0f0f0;
        -moz-box-shadow: 0 0 3px 1px #0d73c14f;
        -webkit-box-shadow: 0 0 3px 1px #0d73c14f;
        box-shadow: 0 0 3px 1px #0d73c14f;
        border-radius: 4px;
        padding: 10px;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        color: #fff;
        margin-bottom: 10px;
        .icon-block{
            display: inline-block;
            width: 45px;
        }
        .head-content{
            display: inline-block;
            width: calc(100% - 45px);
            padding-left: 10px;
            color: #000;
            h4{
                color: #000;
                margin-bottom: 0;
            }
            .stage{
                background-color: #01518e;
                font-size: 10px;
                font-weight: 300;
                width: max-content;
                max-width: 100%;                
                display: block;
                margin: 3px 0;
                padding: 2px 5px;
                border-radius: 4px;
                color: #fff;
            }
            h6{
                color: #000;
            }
        }
    }
    .profile-content{
.content-blocks{
    .content-block{
        -moz-box-shadow: 0 0 3px 1px #0d73c14f;
    -webkit-box-shadow: 0 0 3px 1px #0d73c14f;
    box-shadow: 0 0 3px 1px #0d73c14f;
    border-radius: 4px;
    margin-bottom: 10px;
    .content-head{
        padding: 15px 10px;
        border-bottom: 1px solid #e3e3e3;
   h5{
       font-size: 13px;
       margin: 0;
       color: #969696;
   }
        a.add-new{
            display: block;
            width: max-content;
            max-width: 100%;
            margin: 0 0 0 auto;
            color: $primary;
            font-size: 20px;
        }
    }
    .content-details{
        padding: 15px 10px;
        font-weight: 500;
        a{
            color: #000;
        }
        ul.actions{
            display: block;
            list-style: none;
            padding: 0;
            margin: 5px 0;
            text-align: right;
            li{
                display: inline-block;
                &:not(:last-child){
                    margin-right: 10px;
                }
            }
        }
    }
    }
}
    }
}
.history-date{
    background-color: #f1f1f1;
    border-radius: 7px;
    text-align: center;
    display: block;
    padding: 4px 10px;
    color: $primary;
    width: max-content;
    max-width: 100%;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-size: 12px;
    margin: 15px auto;
}
.chat-block{
    height: 100%;
    display: flex;  
    flex-direction: column-reverse;
    .chat-content{
        display: flex;
        margin-bottom: 15px;
        justify-content: flex-start;
        // transform: translateX(6px);.
        // margin-left: 6px;
        padding-left: 6px;
        .text-inner{
            padding: 10px 20px;
            background-color: #b8d6ee;
            max-width: 80%;
            position: relative;
            color: #000;
            border-radius: 0 4px 4px 4px;
        }
        &.is-you{
            justify-content: flex-end;
            padding-right: 0;
            transform: translateX(-6px);
            .text-inner{
                border-radius: 4px 0 4px 4px;
                background-color: #dbeff6;
                padding: 10px 20px;
            }
            .chat-arrow{
                left: unset;
                right: -6px;
                border-color: transparent currentcolor transparent #dbeff6;
                border-style: solid none solid solid;
                border-width: 0 medium 6px 6px;
            }
        }
        .chat-arrow{
            position: absolute;
            top: 0;
            left: -6px;
            border-color: transparent #b8d6ee transparent currentcolor;
            border-style: solid solid solid none;
            border-width: 0 6px 6px medium;
            position: absolute;
            top: 0;
        }
        .date{
            font-size: 10px;
            letter-spacing: 0.5px;
            font-weight: 300;
            color: #000;
            text-align: right;
        }
    }
}

.mail-item-action{

    .ant-btn{
        font-size: 11px;
        line-height: 25px;
        height: 25px;
        padding: 0 10px;
    }
}

.msg-blk-panel-list{
    .msg-blk-panel{
        display: flex;
        flex-direction: column;
        &:not(:last-of-type){
            margin-bottom: 10px;
        }
        .msg-blk-panel-header{
            padding: 13px;
            border-radius: 5px;
            border: 1px solid #ddd;
            .avatar-status{
                display: flex;
                align-items: center;
                h6{
                    color: #999;
                    margin-top: 3px;
                }
                .avt-hdrnoder{
                    display: flex;
                    align-items: center;
                    .ant-tag{
                        margin-left: 4px;
                        text-transform: capitalize;
                    }
                }
            }
        }
        .msg-blk-panel-body{
            padding: 13px;
            border-radius: 5px;
            border: 1px solid #ddd;
            .subject-blk{
                color: #333;
                font-weight: 700;
            }
            .msg-body{
                img{
                    max-width: 100%;
                }
            }

        }
    }
}
